<template>
  <section class="app-ecommerce-details">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="uiProduct === undefined"
    >
      <h4 class="alert-heading">
        Error fetching product data
      </h4>
      <div class="alert-body">
        No item found with this item slug. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-e-commerce-shop'}"
        >
          Shop
        </b-link>
        for other items.
      </div>
    </b-alert>

    <!-- Content -->
    <b-card
      v-if="uiProduct"
      no-body
    >
    
      <b-card-body>
        <b-row class="my-2">

          <!-- Left: Product Image Container -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-img
                :src="uiProduct.images[0]"
                :alt="`Image of ${uiProduct.name}`"
                class="product-img"
                fluid
                style="max-height:300px"
              />
            </div>
          </b-col>

          <!-- Right: Product Details -->
          <b-col
            cols="12"
            md="8"
          >
  <b-form @submit.prevent="_updateP">
      <br><br>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Label"
            label-for="h-label"
            label-cols-md="3"
          >
            <b-form-input
              v-model="uiProduct.name"
              id="h-label"
              placeholder="Label"
              required
            />
          </b-form-group>
        </b-col>
           <b-col cols="12">
          <b-form-group
            label="Category"
            label-for="h-category"
            label-cols-md="3"
          >
          <v-select
              v-model="cat"
              id="categoryList"
              placeholder="Category"
              :options="categories"
              required
              v-on:change="_changecategory"
            />
          </b-form-group>
        </b-col>
             <b-col cols="12">
          <b-form-group
            label="Sub-Category"
            label-for="h-sub-category"
            label-cols-md="3"
          >
          <v-select
              v-model="Osubcat"
              id="subcategoryList"
              placeholder="Sub-Category"
              :options="subcat"
              required
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Description"
            label-for="h-description"
            label-cols-md="3"
          >
          <b-form-textarea
          id="h-description"
          placeholder="Description"
          rows="3"
          v-model="uiProduct.description"
          max-rows="8"
          class="mb-1 mb-xl-0"
        />
           
          </b-form-group>
        </b-col>
          <b-col cols="12">
          <b-form-group
            label="Price $"
            label-for="h-price"
            label-cols-md="3"
          >
            <b-form-input
              v-model="uiProduct.price"
              id="h-price"
              placeholder="Price"
              required
              type="number"
            />
          </b-form-group>
        </b-col>

              <b-col cols="12">
          <b-form-group
            label="Discount %"
            label-for="h-discount"
            label-cols-md="3"
          >
            <b-form-input
              v-model="uiProduct.discount"
              id="h-discount"
              placeholder="Discount"
              required
              type="number"
            />
          </b-form-group>
        </b-col>

          <b-col cols="12">
          <b-form-group
            label="Transaction type"
            label-for="h-price"
            label-cols-md="3"
            class="mt-1"
          >
            <b-form-radio-group
            v-model="uiProduct.typeTransaction"
            :options="options"
           
            name="radio-validation"
            >
            </b-form-radio-group>
          </b-form-group>

        </b-col>
         <b-col cols="12">
           <b-form-group
            label="Image"
            label-for="h-image"
            label-cols-md="3"
            class="mt-1"
          >
          <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="_uploadimage"
          block
          >
          <span>Upload</span>
          </b-button>
           </b-form-group>
         </b-col>
           <b-col cols="12">
             
         <div>
     <div>
      <b-form
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >

        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in items"
          :id="item._id"
          :key="item._id"
          ref="row"
          class="mt-1"
        >
       <b-col md="3">
            <b-form-group
              class="mt-1"
              label="Option"
            >
             
            </b-form-group>
          </b-col>
          <!-- Item Name -->
          <b-col md="3">
            <b-form-group
              label="Subject"
              label-for="subject"
            >
              <b-form-input
              v-model="item.subject"
                id="subject"
                type="text"
                placeholder="Subject"
              />
            </b-form-group>
          </b-col>

          <!-- Cost -->
          <b-col md="3">
            <b-form-group
              label="Value"
              label-for="value"
            >
              <b-form-input
                          v-model="item.value"

                id="value"
                type="text"
                placeholder="value"
              />
            </b-form-group>
          </b-col>

         

          <!-- Remove Button -->
          <b-col
            
            md="3"
            class="mb-50"
          >
            <b-button
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(index)"
              block
            >
             
              <span>Delete</span>
            </b-button>
          </b-col>
           

        </b-row>
      </b-form>
    </div>
   <br>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="repeateAgain"
      style="float:right;"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Add Option</span>
    </b-button>
  </div>
        </b-col>
      
       
        <!-- submit and reset -->
          <b-col cols="12">
          <b-form-group
      
          >
          <br>
           <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            block
          >
            Edit
          </b-button>
          </b-form-group>
        </b-col>
        
      </b-row>

    </b-form>
      
       

          

         
          </b-col>
        </b-row>
      </b-card-body>

      <!-- Static Content -->
     <!-- <e-commerce-product-details-item-features />-->

      <!-- Static Content -->
      <!-- Slider: Related Products -->
    <!--  <e-commerce-product-details-related-products /> -->
    </b-card>
  </section>
</template>

<script>
import { useRouter } from '@core/utils/utils'
import store from '@/store'
import { ref } from '@vue/composition-api'
import {
  BCard, BCardBody,BFormSelect, BRow, BCol, BImg, BCardText, BLink, BFormRadioGroup, BFormInvalidFeedback, BFormValidFeedback, BFormTextarea, BDropdown, BDropdownItem, BAlert,  BFormGroup, BFormInput, BFormCheckbox, BForm, BButton
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ECommerceProductDetailsItemFeatures from './ECommerceProductDetailsItemFeatures.vue'
import ECommerceProductDetailsRelatedProducts from './ECommerceProductDetailsRelatedProducts.vue'
import { useEcommerceUi } from '../useEcommerce'
import PostsMixin from "../post.mixin.js";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ProductsMixin from "../product.mixin.js";
import SubCategoryMixin from "../../../../mixins/subcategory.mixin";
import CategoryMixin from "../../../../mixins/category.mixin";
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
export default {
  mixins: [ProductsMixin,PostsMixin, CategoryMixin, SubCategoryMixin, heightTransition],
  props:["product"],
  directives: {
    Ripple,
  },
  components: {
    BFormRadioGroup, 
    BFormInvalidFeedback, 
    BFormValidFeedback,
    vSelect,
BFormSelect,
       BFormGroup, 
       BFormInput, 
       BFormCheckbox, 
       BForm, 
       BFormTextarea,
       
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,

    // SFC
    ECommerceProductDetailsItemFeatures,
    ECommerceProductDetailsRelatedProducts,
  },
  computed: {
    state() {
      return Boolean(this.uiProduct.typeTranscation)
    },
  },
  data () {
    return {
      items: [],
      nextTodoId: 2,
      value: null,
      options: [
        { text: 'Sale', value: 'Sale' },
        { text: 'Rent', value: 'Rent' }
      ],
      Osubcat : {},
      cat : {},
      subcat : [],
        selected: 0,

      uiProduct : {
        images : [""],
        option : [{value : ''}],
        postId :''
      }
    }
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  mounted() {
    this.initTrHeight()
    store.dispatch('app-ecommerce/fetchProducts')
    console.log("test p",this.product)
    if(this.product!=undefined) {
          this.uiProduct = this.product
          this.uiProduct.typeTransaction = "Sale"
          this.cat = this.uiProduct.category
          this.Osubcat = this.uiProduct.subCategory
           this.items = []
        this.items = this.uiProduct.option
           var i = this.items.length
while (i--) {
    if (this.items[i].subject === undefined) { 
        this.items.splice(i, 1);
    } 
}
     


    }
  },
  methods : {
     _uploadimage() {
      window.cloudinary
        .openUploadWidget(
          { cloud_name: "dpseth2jc", upload_preset: "elicia" },
          (error, result) => {
            if (!error && result && result.event === "success") {
              console.log("Done uploading..: ", result);
              this.url = result.info.url;
              this.publicId = result.info.public_id;
              this.uiProduct.images[0] = this.url
              //this.newDoctor.photo = this.publicId;
            }
          }
        )
        .open();
    },
     repeateAgain() {
      this.items.push({
        _id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    _changecategory() {
     this.subcat = this.cat.subCategories
     if(this.subcat.length>0)
     this.Osubcat = this.subcat[0].label
     else
     this.Osubcat = ''
     console.log("aziz ",this.Osubcat)
    },
    _updateP() {
       
       var i = this.items.length
while (i--) {
    if (this.items[i].subject === undefined) { 
        this.items.splice(i, 1);
    } 
}


      this.uiProduct.category = this.cat.label
      this.uiProduct.subCategory = this.Osubcat
      this.uiProduct.option = this.items
      this.uiProduct.option.forEach(element => {
        delete element._id
      });
        store.dispatch('app-ecommerce/editProduct', this.uiProduct).then(data => {
             if(data!=="error") {
                this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Product successfully updated',
                   icon:"EditIcon",
                  variant: 'success',
                },
              })
              this.$router.push({
              name: "apps-e-commerce-shop"
              });
             

              }else{
                this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failed update',
                   icon:"EditIcon",
                  variant: 'danger',
                },
              })
              }       
        })
    
    }
     
  },
  watch: {
    cat: function() {
       this.subcat = this.cat.subCategories
       if(this.subcat!==undefined) {
          if(this.subcat.length>0)
          this.Osubcat = this.subcat[0].label
          else
          this.Osubcat = ''
       }
    },
   posts: function() {
      for(let i=0; i<this.posts.length; i++) {
        if(this.posts[i]._id===this.$route.params.id) {
          this.uiProduct = this.posts[i].product
          this.uiProduct.postId = this.posts[i]._id
        }
      }
        this.uiProduct.typeTransaction = "Sale"
        this.cat = this.uiProduct.category
        console.log("cat ",this.cat)
        this.Osubcat = this.uiProduct.subCategory
        this.items = []
        this.items = this.uiProduct.option
        this.items.forEach(element => {
                    this.repeateAgain()

        });
           var i = this.items.length
while (i--) {
    if (this.items[i].subject === undefined) { 
        this.items.splice(i, 1);
    } 
}

         

        console.log("options ",this.uiProduct)
   } 
  },
  setup() {
    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

 
    // UI
    const selectedColor = ref(null)


    return {
      selectedColor,
      handleCartActionClick,
      toggleProductInWishlist,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
@import '@core/scss/vue/libs/vue-select.scss';
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
